.introduction {
  max-width: 32.75rem;
  height: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  top: 55%;
  right: 40%;
  transform: translate(10%, -50%);
  left: 6.5rem;
  margin: auto 0;
  opacity: 0;
  scale: 0.8;
}

.logo-black {
  width: 100%;
  height: auto;
}

img#logoBlack {
  filter: invert(var(--adxr-logo));
}

.introduction__content {
  /* padding: 1rem 0rem; */
  font-weight: lighter;
}

.fade-in {
  animation: fadeIn ease 3s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*MEDIA QUERIES*/
/*=========== BREAKPOINTS =============*/
/*Large  Screens and Devices (e.g.:DESKTOPS)*/
@media screen and (max-width: 1200px) {
  .introduction {
    max-width: 33%;
    justify-content: center;
    transform: translate(5%, -45%);
    margin: auto 0;
    opacity: 0;
  }
}

/*Medium Screens and Devices (e.g.:TABLETS)*/
@media screen and (max-width: 768px) {
  .introduction {
    max-width: 33%;
    justify-content: center;
    position: absolute;
    right: 0;
    left: 1rem;
    transform: translate(5%, -45%);
    margin: auto 0;
    opacity: 0;
  }
}

/*Small Screens and Devices (e.g.:MOBILE)*/
@media screen and (max-width: 480px) {
  .introduction {
    position: relative;
    max-width: 100%;
    left: 0;
    top: 0rem;
    transform: translate(0);
    padding: 0 1rem;
    opacity: 0;
  }
}
