/*
========================================
Global Styles
======================================== */

body,
html {
  padding: 0;
  margin: 0;
  overflow: hidden;
  height: 100%;
}

button {
  color: var(--text-primary);
  background-color: var(--background-primary);
  border: 2px var(--text-primary) solid;
  float: right;
  transition: all 0.5s;
}

.main {
  background-color: var(--background-primary);
}
/*
========================================
Variable CSS Styles
======================================== */
:root {
  --background-primary: white;
  --background-secondary: black;
  --text-primary: #191a1b;
  --text-secondary: #f5f4ef;
  --text-hover: #00000073;
  --adxr-logo: 0%;
  --newsletter-theme: 0%;
}
/*
    reference: https://css-tricks.com/dark-modes-with-css/
  */
[data-theme="dark"] {
  --background-primary: black;
  --background-secondary: white;
  --text-primary: #f5f4ef;
  --text-secondary: #191a1b;
  --text-hover: #ffffff67;
  --adxr-logo: 100%;
  --newsletter-theme: 100%;
}

/*
======================================== 
Typography Styles
======================================== */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");

/* @font-face {
  font-family: "Inter";
  src: url(./Assets/Fonts/Inter-Regular.ttf);
} */

@font-face {
  font-family: "Neue";
  src: url(./Assets/Fonts/PPNeueMontreal-Medium.otf);
}

@font-face {
  font-family: "Neue";
  src: url(./Assets/Fonts/NeueMontreal-Regular.otf);
}

/* @font-face {
  font-family: "Argentun";
  src: url(./Assets/Fonts/ArgentumSans-Regular.ttf);
} */

@font-face {
  font-family: "Argentun-light";
  src: url(./Assets/Fonts/ArgentumSans-Light.ttf);
}

/* @font-face {
  font-family: "Argentun-medium";
  src: url(./Assets/Fonts/ArgentumSans-Medium.ttf);
} */

p {
  font-family: "Argentun-light";
  font-size: 1.5rem;
  font-weight: lighter;
  color: var(--text-primary);
}

h1, h2, h3, h4, h5, h6, label {
  font-family: "Neue", sans-serif;
}

/*
======================================== 
General Component Styles
======================================== */

/*dark-light mode button*/
#switch-theme {
  color: var(--text-primary);
  border: 1px solid var(--text-primary);
  border-radius: 10px;
  margin: 2rem;
  padding: 1rem;
  cursor: pointer;
  font-size: 1.2em;
  display: none;
  position: fixed;
}

#switch-theme:hover {
  color: var(--text-hover);
}

.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 44px;
  opacity: 1;
  margin: 1rem;
  z-index: 9;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 36px;
  width: 36px;
  left: 4px;
  bottom: 4px;
  background-color: #000;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #000;
  color: #fff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #000;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

input:checked + .slider::before {
  background-color: #fff;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 15%;
  height: auto;
  cursor: pointer;
  display: none;

  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;

}

img#back-to-top-button {
  filter: invert(var(--adxr-logo));
}

.back-to-top.flip-up {
  transform: rotate(0deg);
}

.back-to-top.flip-down {
  transform: rotate(180deg);
}

/*MEDIA QUERIES*/
/*=========== BREAKPOINTS =============*/
/*Large  Screens and Devices (e.g.:DESKTOPS)*/
@media only screen and (min-width: 768px) {
  #back-to-top-button {
    display: none !important;
  }
}

@media screen and (max-width: 1200px) {
}

/*Medium Screens and Devices (e.g.:TABLETS)*/
@media screen and (max-width: 768px) {
}

/*Small Screens and Devices (e.g.:MOBILE)*/
@media screen and (max-width: 480px) {
  /*dark-light mode button*/
  #switch-theme {
    color: var(--text-primary);
    border: none;
    margin: 1rem 0.5rem;
    cursor: pointer;
    font-size: 1rem;
  }

  body, html {
    overflow-y: scroll;
    height: 100vh;
  }
}
