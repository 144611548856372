#mc_embed_signup {
    background: transparent; 
    clear: left; 
    font-family: "Intern", sans-serif;
    font-size: 1.5rem;
    color: var(--text-primary);
    width: 100%;
}

#mc_embed_signup form {
    margin: 0;
}

#mc_embed_signup {
    color: var(--text-primary);
}

#mc_embed_signup_scroll h4 {
    font-weight: lighter;
}

#mc_embed_signup .helper_text {
    display: none;
}

#mc_embed_signup .brandingLogo {
    display: none;
}

#mc_embed_signup .button {
    background-color: transparent;
    border: 1px solid var(--text-primary);
    color: var(--text-primary);
}

.mc-field-group input {
    background-color: transparent;
    color: #fff;
}

.mc-field-group input:focus {
    outline: none;
}

/*MEDIA QUERIES*/
/*=========== BREAKPOINTS =============*/
/*Large  Screens and Devices (e.g.:DESKTOPS)*/
@media screen and (max-width: 1200px) {
}
  
/*Medium Screens and Devices (e.g.:TABLETS)*/
@media screen and (max-width: 768px) {
}
  
/*Small Screens and Devices (e.g.:MOBILE)*/
@media screen and (max-width: 480px) {
    #mc_embed_signup_scroll h4 {
        font-weight: lighter;
        font-size: larger;
    }
}
